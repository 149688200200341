import React from 'react';
import logoMobil from '../images/mobile-startseite/Stiftungslogo_Smartphone_einzeilig.png';
import './index.css';
import BasicStructure from '../components/basic-structure/basic-structure';
import { Link } from 'gatsby';
import '../media-queries/startseite-media-queries.css';
import TitleMetaTags from '../components/title-meta-tags/title-meta-tags';
import ContentContainer from '../components/content-container/content-container';
import Footer from '../components/footer/footer';
import Menubar from '../components/menu/menubar';
import BurgerMenu from '../components/burgermenu/burgermenu';
import Header from '../components/header/header';

function Index(): JSX.Element {
  return (
    <BasicStructure>
      <TitleMetaTags title="Internationale Stiftung zur Foerderung von Kultur und Zivilisation" />
      <Header>
        <Menubar styleClass="main-menu" />
        <BurgerMenu />
      </Header>
      <ContentContainer>
        <div className="startseite__stiftung">
          <Link to="/die-stiftung">
            <img
              className="startseite__logo"
              src={logoMobil}
              alt="Logo Stiftung"
              width="100%"
              height="100%"
            />
          </Link>

          <div className="startseite__langzeitprojekte-text">
            <p className="startseite__text"> Wichtige Langzeitprojekte</p>
            <a
              className="startseite__text-headlines"
              href={'/projekte/musik-am-nachmittag'}
            >
              Musik am Nachmittag
            </a>
            <p className="startseite__text">
              seit 1996 annähernd 15.000 live Konzerte mit klassischer Musik für
              über 1.000.000 begeisterter Senioren in Altenheimen und festlichen
              Sälen, initiiert von Erich Fischer.
            </p>

            <a
              className="
                startseite__text-headlines"
              href={'projekte/musik-fuer-schueler'}
            >
              Musik für Schüler
            </a>
            <p className="startseite__text">
              seit 1998 richtungsweisende Musikworkshops, über 2.350 live
              Konzerte an Schulen für rund 167.000 Schüler. Musik-Zentren mit
              Klavier- und Chorunterricht unter dem von Erich Fischer
              konzipierten Motto «Musizieren statt Konsumieren».
            </p>

            <a
              className="startseite__text-headlines"
              href={'projekte/toujours-mozart'}
            >
              Toujours Mozart
            </a>
            <p className="startseite__text">
              seit 1997 ein einzigartiges, heiteres Mozartfest mit dem Ziel,
              alte Mozartianer neu zu begeistern und neue Mozartfreunde zu
              gewinnen. Idee und Konzept Erich Fischer.
            </p>

            <p className="startseite__text startseite__little-headline">
              Weitere wichtige Langzeitprojekte
            </p>

            <p className="startseite__text-bottom-headlines">
              Musik auf Rädern
              <br />
              Musik hinter Gittern | Amnesty National
            </p>

            <p className="startseite__text">
              Wir danken allen, die unsere Stiftungsprojekte ideell und
              materiell unterstützen.
            </p>
          </div>
        </div>
      </ContentContainer>
      <Footer />
    </BasicStructure>
  );
}
export default Index;
